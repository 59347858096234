<template>
  <tr>
    <template v-if="loading">
      <td colspan="8" class="text-center">
        <span class="font-weight-bolder" style="font-size: 1.5em">Loading ...</span>
      </td>
    </template>
    <template v-else>
      <td>
        <a class="font-weight-bold">{{ dt?.id }}</a>
      </td>
      <td>
        <span class="font-weight-normal">{{
          dt.GiftCardEntry?.description
        }}</span>
      </td>
      <td>
        <span class="font-weight-normal">{{ dt?.amount }}</span>
      </td>
      <td>
        <span class="font-weight-normal">{{ dt?.payout_amount }}</span>
      </td>
      <td>
        <router-link :to="`/cards/sell-t/${dt?.id}`" class="font-weight-normal text-blue">{{ dt.reference }}</router-link>
      </td>
      <td>
        <span class="font-weight-normal" :class="dt.status === 'DONE' ? 'text-success' : ''">{{ dt.status }}</span>
        <span v-if="dt.is_processing == true" class="font-weight-normal"
          :class="dt.is_processing === true && dt.status == 'SUBMITTED' ? 'text-warning' : ''">({{
            dt.is_processing == true ? 'Card processing' : '' }})</span>
      </td>

      <td>
        <span class="font-weight-normal">{{ dt?.created_at }}</span>
      </td>
      <td>
        <span class="font-weight-normal">{{ dt?.description }}</span>
      </td>
      <td>
        <div class="dropdown">
          <button class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" type="button"
            id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" :disabled="loading">
            <span class="icon icon-sm">
              <span class="fas fa-ellipsis-h icon-dark"></span>
            </span>
            <span class="sr-only">Toggle Dropdown</span>
          </button>
          <div class="dropdown-menu">
            <!-- <button class="px-3 py-2 cursor-pointer btn btn-light" @click="maybeGotoDetails(dt?.id)">
              See Details
            </button> -->
            <router-link :to="`/cards/sell-t/${dt?.id}`" class="dropdown-item text-dark">
              <span class="fas fa-eye mr-1"></span>
              See Details
            </router-link>
            <div v-if="dt.is_processing == true">
              <button v-admin="100" @click.prevent="$emit('open-trade', dt.id)" v-if="dt.is_processing == true"
                class="btn btn-primary m-2 w-50">
                Reopen
              </button>
            </div>
          </div>
        </div>
      </td>
    </template>
  </tr>
</template>

<script lang="ts">
import { apiGet } from "@/api";
import { Toast } from "@/utils/notify";
import { defineComponent, inject, ref } from "vue";

export default defineComponent({
  name: "SellGiftCardTransactionRow",
  props: {
    dt: {
      type: Object,
      required: true,
    },
  },
  emits: ["open-trade"],
  setup(props, ctx) {
    const RequestConfirmation = inject("RequestConfirmation");

    const loading = ref(false);
    const admin = ref<{ username: string } | null>(null);

    // const maybeGotoDetails = (id: string) => {
    //   if (props.dt.is_processing && props.dt.processed_by) {
    //     getAdminDetails(props.dt.processed_by);
    //   } else {
    //     router.push(`/cards/sell-t/${id}`);
    //   }
    // };

    const getAdminDetails = async (id: string) => {
      loading.value = true;
      try {
        const response = await apiGet(`/user-account/${id}`);
        admin.value = response.data.data.user as { username: string };
        Toast.fire({
          icon: "success",
          title: `This transaction is being processed by ${admin.value?.username}`,
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    // const openTrade = () => {
    //   RequestConfirmation({
    //     title: "Open Trade",
    //     message: "Are you sure you want to open this trade?",
    //     onConfirm: () => {
    //       ctx.emit("open-trade", props.dt.id);
    //     },
    //   });
    // };

    return {
      loading,
      RequestConfirmation,
      // maybeGotoDetails,
      getAdminDetails,
    };
  },
});
</script>

<style scoped></style>
